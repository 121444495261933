import React from 'react';
import { motion } from 'framer-motion';

function HowItWorks() {
  const steps = [
    { number: 1, text: "Upload your resume (PDF, DOC, or DOCX format)" },
    { number: 2, text: "Our AI analyzes your resume against industry standards" },
    { number: 3, text: "Receive instant, detailed feedback and suggestions" },
    { number: 4, text: "Apply the recommendations to improve your resume" },
    { number: 5, text: "Repeat the process to further refine your resume" }
  ];

  return (
    <section id="how-it-works" className="py-20 bg-white">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <motion.h2 
          className="text-3xl font-bold text-center text-gray-900 mb-12"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          How It Works
        </motion.h2>
        <div className="max-w-4xl mx-auto">
          {steps.map((step, index) => (
            <motion.div 
              key={step.number} 
              className="flex items-center mb-8"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.2, duration: 0.6 }}
            >
              <div className="flex-shrink-0 w-12 h-12 bg-blue-500 text-white rounded-full flex items-center justify-center font-bold text-lg mr-4">
                {step.number}
              </div>
              <div className="flex-grow">
                <p className="text-gray-700 font-medium">{step.text}</p>
              </div>
              {index < steps.length - 1 && (
                <div className="h-16 w-px bg-blue-200 mx-6 my-1"></div>
              )}
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default HowItWorks;
