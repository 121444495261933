import React, { useState } from 'react';
import { motion } from 'framer-motion';

function Hero() {
  const [email, setEmail] = useState('');
  const [file, setFile] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', { email, file });
  };

  return (
    <section className="bg-gradient-to-r from-purple-600 to-indigo-600 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row items-start justify-between">
          <div className="lg:w-1/2 mb-6 lg:mb-0 text-white">
            <h1 className="text-3xl font-extrabold sm:text-4xl md:text-5xl mb-2">
              🚀 Supercharge Your Career
            </h1>
            <h2 className="text-xl font-bold mb-2">
              AI-Powered Resume Analysis & Optimization
            </h2>
            <p className="text-lg mb-2">
              Unlock your professional potential with ResumeFeedback.pro! 🔓✨
            </p>
            <ul className="text-base grid grid-cols-2 gap-x-4 gap-y-1">
              <li>📊 ATS compatibility scores</li>
              <li>💡 Tailored suggestions</li>
              <li>🎯 Job-specific optimization</li>
              <li>🔍 Uncover hidden strengths</li>
              <li>⏱️ AI-powered time-saving</li>
            </ul>
          </div>
          
          <div className="lg:w-5/12">
            <div className="bg-white p-4 rounded-lg shadow-xl">
              <h3 className="text-xl font-bold text-indigo-600 mb-3 text-center">
                Analyze Your Resume Now! 📝
              </h3>
              <form onSubmit={handleSubmit} className="space-y-2">
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  className="block w-full px-3 py-2 border border-indigo-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="file"
                  id="resume"
                  name="resume"
                  required
                  accept=".pdf,.doc,.docx"
                  className="hidden"
                  onChange={(e) => setFile(e.target.files[0])}
                />
                <label
                  htmlFor="resume"
                  className="block w-full px-3 py-2 border border-indigo-300 rounded-md text-indigo-600 bg-indigo-50 hover:bg-indigo-100 cursor-pointer text-center"
                >
                  {file ? file.name : '📎 Upload Your Resume'}
                </label>
                <motion.button
                  type="submit"
                  className="w-full py-2 px-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gradient-to-r from-pink-500 to-orange-500 hover:from-pink-600 hover:to-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  🔍 Analyze Now
                </motion.button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;