import React from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Footer from '../components/Footer';

function ResumeWritingTips() {
  return (
    <div className="font-sans bg-blue-50">
      <Header />
      <main className="w-full px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex flex-col md:flex-row bg-white shadow-lg rounded-lg overflow-hidden border-t-4 border-blue-500">
          <aside className="md:w-1/4 bg-blue-100 p-6 sticky top-0 h-screen overflow-y-auto">
            <nav>
              <ul className="space-y-4">
                <li>
                  <a href="https://resumefeedback.pro#introduction" className="block text-blue-700 hover:text-blue-900 transition-colors duration-200">
                    <span className="font-semibold">Introduction</span>
                    <p className="text-sm text-blue-600">Crafting an effective resume</p>
                  </a>
                </li>
                <li>
                  <a href="https://resumefeedback.pro#tailor-resume" className="block text-blue-700 hover:text-blue-900 transition-colors duration-200">
                    <span className="font-semibold">Tailor Your Resume</span>
                    <p className="text-sm text-blue-600">Customizing for each position</p>
                  </a>
                </li>
                <li>
                  <a href="https://resumefeedback.pro#clear-format" className="block text-blue-700 hover:text-blue-900 transition-colors duration-200">
                    <span className="font-semibold">Clear Format</span>
                    <p className="text-sm text-blue-600">Choosing a professional layout</p>
                  </a>
                </li>
                <li>
                  <a href="https://resumefeedback.pro#strong-summary" className="block text-blue-700 hover:text-blue-900 transition-colors duration-200">
                    <span className="font-semibold">Strong Summary</span>
                    <p className="text-sm text-blue-600">Creating an impactful introduction</p>
                  </a>
                </li>
                <li>
                  <a href="https://resumefeedback.pro#achievements" className="block text-blue-700 hover:text-blue-900 transition-colors duration-200">
                    <span className="font-semibold">Focus on Achievements</span>
                    <p className="text-sm text-blue-600">Highlighting accomplishments</p>
                  </a>
                </li>
                <li>
                  <a href="https://resumefeedback.pro#keywords" className="block text-blue-700 hover:text-blue-900 transition-colors duration-200">
                    <span className="font-semibold">Use Keywords</span>
                    <p className="text-sm text-blue-600">Optimizing for ATS</p>
                  </a>
                </li>
              </ul>
            </nav>
          </aside>
          <article className="md:w-3/4 px-6 py-8 sm:p-10">
            <h1 className="text-4xl sm:text-5xl font-extrabold text-blue-900 mb-4 leading-tight">
              Essential Resume Writing Tips: Crafting Your Path to Success
            </h1>
            <div className="prose prose-blue max-w-none">
              <p id="introduction" className="lead text-xl text-blue-700 mb-8">
                Crafting an effective resume requires careful thought and strategy. Here are some key tips to help you create a resume that stands out and lands you your dream job.
              </p>

              <h2 id="tailor-resume" className="text-3xl font-bold text-blue-800 mt-12 mb-6">1. Tailor Your Resume to the Job</h2>
              <p className="mb-6 text-blue-600">
                Customize your resume for each position you apply to, highlighting relevant skills and experiences that align with the job requirements.
              </p>

              <h2 id="clear-format" className="text-3xl font-bold text-blue-800 mt-12 mb-6">2. Use a Clear, Professional Format</h2>
              <p className="mb-6 text-blue-600">
                Choose a clean, easy-to-read layout with consistent formatting throughout. A well-organized resume demonstrates your attention to detail and professionalism.
              </p>

              <h2 id="strong-summary" className="text-3xl font-bold text-blue-800 mt-12 mb-6">3. Start with a Strong Summary</h2>
              <p className="mb-6 text-blue-600">
                Begin with a brief, impactful summary that highlights your key qualifications and career objectives. This serves as your elevator pitch to potential employers.
              </p>

              <h2 id="achievements" className="text-3xl font-bold text-blue-800 mt-12 mb-6">4. Focus on Achievements, Not Just Duties</h2>
              <p className="mb-6 text-blue-600">
                Emphasize your accomplishments and quantify them where possible, rather than simply listing job responsibilities. Use action verbs and specific metrics to showcase your impact.
              </p>

              <h2 id="keywords" className="text-3xl font-bold text-blue-800 mt-12 mb-6">5. Use Keywords Wisely</h2>
              <p className="mb-6 text-blue-600">
                Incorporate relevant industry keywords to optimize your resume for Applicant Tracking Systems (ATS). This increases your chances of getting past initial screenings and into the hands of hiring managers.
              </p>
            </div>
          </article>
        </div>
      </main>
      <Hero />
      <Footer />
    </div>
  );
}

export default ResumeWritingTips;
