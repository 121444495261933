import React from 'react';
import { motion } from 'framer-motion';

function Features() {
  const features = [
    {
      icon: '🚀',
      title: 'Instant Analysis',
      description: 'Get detailed feedback on your resume within seconds.'
    },
    {
      icon: '🎯',
      title: 'ATS Optimization',
      description: 'Ensure your resume passes through Applicant Tracking Systems.'
    },
    {
      icon: '📊',
      title: 'Industry Insights',
      description: 'Receive tailored advice based on your specific industry.'
    },
    {
      icon: '💡',
      title: 'Smart Suggestions',
      description: 'Get AI-powered recommendations to improve your resume.'
    }
  ];

  return (
    <section className="bg-gradient-to-b from-indigo-600 to-purple-600 py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-white text-center mb-12">
          Powerful Features to Boost Your Career
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              className="bg-white rounded-lg shadow-xl p-6"
              whileHover={{ scale: 1.05 }}
              transition={{ type: 'spring', stiffness: 300 }}
            >
              <div className="text-4xl mb-4">{feature.icon}</div>
              <h3 className="text-xl font-semibold text-indigo-600 mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Features;
