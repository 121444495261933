import React from 'react';
import { motion } from 'framer-motion';

function Testimonials() {
  const testimonials = [
    {
      quote: "ResumeFeedback.pro is the best thing since sliced bread! I mean, who needs actual testimonials when you can just make them up?",
      author: "Not Sarah K., Definitely Not a Marketing Professional"
    },
    {
      quote: "I'm pretty sure I got a job because of ResumeFeedback.pro. Or maybe it was the pizza I ate for lunch. Hard to say.",
      author: "Possibly Michael T., Could Be a Software Engineer"
    },
    {
      quote: "This tool suggested I add 'proficient in time management' to my skills. Ironically, I was too busy procrastinating to implement the changes.",
      author: "Totally Real Emily R., Master of Deadline Surfing"
    }
  ];

  return (
    <section className="bg-gradient-to-r from-pink-500 to-orange-500 py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-white text-center mb-12">
          What Our Users Say (Or Would Say If We Had Any)
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <motion.div
              key={index}
              className="bg-white rounded-lg shadow-xl p-6"
              whileHover={{ scale: 1.05 }}
              transition={{ type: 'spring', stiffness: 300 }}
            >
              <p className="text-gray-600 italic mb-4">"{testimonial.quote}"</p>
              <p className="text-indigo-600 font-semibold">- {testimonial.author}</p>
            </motion.div>
          ))}
        </div>
        <p className="text-sm text-white text-center mt-8 italic">
          Disclaimer: These testimonials are about as real as your chances of becoming a unicorn trainer.
        </p>
      </div>
    </section>
  );
}

export default Testimonials;