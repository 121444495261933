import React from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Features from '../components/Features';
import HowItWorks from '../components/HowItWorks';
import Testimonials from '../components/Testimonials';
import FAQ from '../components/FAQ';
import Footer from '../components/Footer';

function Home() {
  return (
    <div className="font-sans">
      <Header />
      <main className="pt-16"> {/* Add padding-top to account for fixed header */}
        <h1 className="sr-only">ResumeFeedback.pro - Free AI-Powered Resume Analysis and Optimization</h1>
        <Hero />
        <Features />
        <HowItWorks />
        <Testimonials />
        <FAQ />
      </main>
      <Footer />
    </div>
  );
}

export default Home;
