import React from 'react';
import { motion } from 'framer-motion';

function FAQ() {
  const faqItems = [
    { question: "What is the accuracy of the AI feedback?", answer: "Our AI is trained on a vast dataset of resumes and job descriptions, ensuring highly accurate and relevant feedback tailored to your resume." },
    { question: "Can I use the service for multiple industries?", answer: "Yes, our AI is designed to adapt its feedback based on your target industry and job role, ensuring you receive relevant insights for your career goals." },
    { question: "What is the turnaround time for the analysis?", answer: "You can expect to receive your feedback instantly, within seconds of uploading your resume." },
    { question: "Why should I use ResumeFeedback.pro?", answer: "By using ResumeFeedback.pro, you'll gain a competitive edge in the job market with actionable insights to improve your resume, increasing your chances of landing an interview and securing your dream job." }
  ];

  return (
    <section id="faq" className="py-20 bg-gray-50">
      <div className="container mx-auto px-6">
        <motion.h2 
          className="text-3xl font-bold text-center text-gray-900 mb-12"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          Frequently Asked Questions
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {faqItems.map((item, index) => (
            <motion.div 
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1, duration: 0.6 }}
              className="bg-white rounded-lg shadow-md p-6"
            >
              <h3 className="text-xl font-semibold mb-2 text-blue-600">{item.question}</h3>
              <p className="text-gray-600">{item.answer}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default FAQ;