import React from 'react';

function Footer() {
  return (
    <footer className="bg-blue-600 text-white py-12">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h4 className="text-2xl font-bold mb-4">ResumeFeedback.pro</h4>
            <p className="text-blue-100">Elevate your career with AI-powered resume analysis and optimization.</p>
          </div>
          <div>
            <h4 className="text-2xl font-bold mb-4">Quick Links</h4>
            <ul className="space-y-2">
              <li><a href="https://resumefeedback.pro/importance-of-good-resume" className="text-blue-200 hover:text-white transition-colors duration-300">Why Good Resumes Matter</a></li>
              <li><a href="https://resumefeedback.pro/resume-writing-tips" className="text-blue-200 hover:text-white transition-colors duration-300">Resume Writing Tips</a></li>
              <li><a href="https://resumefeedback.pro/ats-optimization" className="text-blue-200 hover:text-white transition-colors duration-300">ATS Optimization</a></li>
              <li><a href="https://resumefeedback.pro/industry-specific-resumes" className="text-blue-200 hover:text-white transition-colors duration-300">Industry-Specific Resumes</a></li>
              <li><a href="https://resumefeedback.pro/common-resume-mistakes" className="text-blue-200 hover:text-white transition-colors duration-300">Common Resume Mistakes</a></li>
            </ul>
          </div>
        </div>
        <div className="border-t border-blue-400 mt-8 pt-8 text-center">
          <p className="text-blue-200">&copy; 2024 ResumeFeedback.pro. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;