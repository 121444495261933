import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import ImportanceOfGoodResume from './pages/ImportanceOfGoodResume';
import ResumeWritingTips from './pages/ResumeWritingTips';
import ATSOptimization from './pages/ATSOptimization';
import IndustrySpecificResumes from './pages/IndustrySpecificResumes';
import CommonResumeMistakes from './pages/CommonResumeMistakes';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/importance-of-good-resume" element={<ImportanceOfGoodResume />} />
        <Route path="/resume-writing-tips" element={<ResumeWritingTips />} />
        <Route path="/ats-optimization" element={<ATSOptimization />} />
        <Route path="/industry-specific-resumes" element={<IndustrySpecificResumes />} />
        <Route path="/common-resume-mistakes" element={<CommonResumeMistakes />} />
      </Routes>
    </Router>
  );
}

export default App;
