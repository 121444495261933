import React, { useState } from 'react';
import { motion } from 'framer-motion';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleTryClick = () => {
    // Removed the setShowHero(true) call
  };

  const menuItems = [
    { title: 'Why Good Resumes', href: 'https://resumefeedback.pro/importance-of-good-resume' },
    { title: 'Writing Tips', href: 'https://resumefeedback.pro/resume-writing-tips' },
    { title: 'ATS Optimization', href: 'https://resumefeedback.pro/ats-optimization' },
    { title: 'Industry Resumes', href: 'https://resumefeedback.pro/industry-specific-resumes' },
    { title: 'Common Mistakes', href: 'https://resumefeedback.pro/common-resume-mistakes' },
  ];

  return (
    <header className="bg-white shadow-sm fixed w-full z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4">
          <div className="flex items-center">
            <a href="/" className="text-2xl font-bold text-gray-800">
              ResumeFeedback<span className="text-blue-500">.pro</span>
            </a>
          </div>
          <nav className="hidden md:flex space-x-8">
            {menuItems.map((item, index) => (
              <motion.a
                key={index}
                href={item.href}
                className="text-sm font-medium text-gray-600 hover:text-blue-500 transition-colors duration-200"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {item.title}
              </motion.a>
            ))}
          </nav>
          <div className="hidden md:flex items-center">
            <motion.button
              onClick={handleTryClick}
              className="inline-flex items-center justify-center px-6 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-500 hover:bg-blue-600"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Try It Free
            </motion.button>
          </div>
          <div className="md:hidden">
            <button
              type="button"
              className="text-gray-600 hover:text-blue-500 focus:outline-none"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <span className="sr-only">Open menu</span>
              {isMenuOpen ? (
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              ) : (
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <motion.div 
        className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'}`}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: isMenuOpen ? 1 : 0, y: isMenuOpen ? 0 : -20 }}
        transition={{ duration: 0.3 }}
      >
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          {menuItems.map((item, index) => (
            <a
              key={index}
              href={item.href}
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-blue-500 hover:bg-gray-50"
            >
              {item.title}
            </a>
          ))}
          <button
            onClick={handleTryClick}
            className="w-full text-center px-3 py-2 rounded-md text-base font-medium text-white bg-blue-500 hover:bg-blue-600"
          >
            Try It Free
          </button>
        </div>
      </motion.div>
    </header>
  );
}

export default Header;