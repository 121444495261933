import React from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Footer from '../components/Footer';

function IndustrySpecificResumes() {
  return (
    <div className="font-sans bg-blue-50">
      <Header />
      <main className="w-full px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex flex-col md:flex-row bg-white shadow-lg rounded-lg overflow-hidden border-t-4 border-blue-500">
          <aside className="md:w-1/4 bg-blue-100 p-6 sticky top-0 h-screen overflow-y-auto">
            <nav>
              <ul className="space-y-4">
                <li>
                  <a href="https://resumefeedback.pro#introduction" className="block text-blue-700 hover:text-blue-900 transition-colors duration-200">
                    <span className="font-semibold">Introduction</span>
                    <p className="text-sm text-blue-600">Why industry-specific resumes matter</p>
                  </a>
                </li>
                <li>
                  <a href="https://resumefeedback.pro#technology" className="block text-blue-700 hover:text-blue-900 transition-colors duration-200">
                    <span className="font-semibold">Technology</span>
                    <p className="text-sm text-blue-600">Tailoring for tech roles</p>
                  </a>
                </li>
                <li>
                  <a href="https://resumefeedback.pro#finance" className="block text-blue-700 hover:text-blue-900 transition-colors duration-200">
                    <span className="font-semibold">Finance</span>
                    <p className="text-sm text-blue-600">Crafting for financial positions</p>
                  </a>
                </li>
                <li>
                  <a href="https://resumefeedback.pro#healthcare" className="block text-blue-700 hover:text-blue-900 transition-colors duration-200">
                    <span className="font-semibold">Healthcare</span>
                    <p className="text-sm text-blue-600">Optimizing for medical fields</p>
                  </a>
                </li>
                <li>
                  <a href="https://resumefeedback.pro#marketing" className="block text-blue-700 hover:text-blue-900 transition-colors duration-200">
                    <span className="font-semibold">Marketing</span>
                    <p className="text-sm text-blue-600">Showcasing creative talents</p>
                  </a>
                </li>
              </ul>
            </nav>
          </aside>
          <article className="md:w-3/4 px-6 py-8 sm:p-10">
            <h1 className="text-4xl sm:text-5xl font-extrabold text-blue-900 mb-4 leading-tight">
              Crafting Industry-Specific Resumes: Your Key to Success
            </h1>
            <div className="prose prose-blue max-w-none">
              <p id="introduction" className="lead text-xl text-blue-700 mb-8">
                In today's competitive job market, a one-size-fits-all approach to resume writing simply doesn't cut it. Different industries have unique expectations and value specific skills and experiences. Learn how to tailor your resume for maximum impact in your chosen field.
              </p>

              <h2 id="technology" className="text-3xl font-bold text-blue-800 mt-12 mb-6">Technology: Showcasing Your Tech Prowess</h2>
              <p className="mb-6 text-blue-600">
                The tech industry moves at lightning speed, and your resume needs to keep pace. Here's how to make your tech resume stand out:
              </p>
              <ul className="list-disc pl-6 mb-8 text-blue-700">
                <li className="mb-3"><strong className="text-blue-900">Highlight Technical Skills:</strong> Create a dedicated "Technical Skills" section. List programming languages, frameworks, and tools you're proficient in.</li>
                <li className="mb-3"><strong className="text-blue-900">Showcase Your Code:</strong> Include links to your GitHub profile or online portfolio. This gives potential employers a tangible look at your work.</li>
                <li className="mb-3"><strong className="text-blue-900">Emphasize Projects and Impact:</strong> Detail key projects you've worked on, focusing on your role and the project's impact. Use metrics where possible (e.g., "Reduced load times by 40% through code optimization").</li>
                <li className="mb-3"><strong className="text-blue-900">Stay Current:</strong> The tech world evolves rapidly. Highlight your most up-to-date skills and any continuous learning efforts.</li>
              </ul>

              <h2 id="finance" className="text-3xl font-bold text-blue-800 mt-12 mb-6">Finance: Demonstrating Fiscal Expertise</h2>
              <p className="mb-6 text-blue-600">
                In the world of finance, precision and analytical skills are paramount. Here's how to craft a resume that speaks the language of finance:
              </p>
              <ul className="list-disc pl-6 mb-8 text-blue-700">
                <li className="mb-3"><strong className="text-blue-900">Quantify Achievements:</strong> Use numbers to showcase your impact. For example, "Managed a $50M portfolio with a 15% year-over-year growth".</li>
                <li className="mb-3"><strong className="text-blue-900">Highlight Certifications:</strong> Prominently display relevant certifications like CFA, CPA, or Series 7. These credentials carry significant weight in the finance sector.</li>
                <li className="mb-3"><strong className="text-blue-900">Demonstrate Software Proficiency:</strong> List your experience with financial software and tools such as Bloomberg Terminal, Excel (advanced functions), and financial modeling.</li>
                <li className="mb-3"><strong className="text-blue-900">Showcase Analytical Skills:</strong> Emphasize your ability to analyze complex data and translate it into actionable insights.</li>
              </ul>

              <h2 id="healthcare" className="text-3xl font-bold text-blue-800 mt-12 mb-6">Healthcare: Caring for Your Career</h2>
              <p className="mb-6 text-blue-600">
                In healthcare, your resume should reflect both your technical expertise and your commitment to patient care. Here's how to structure it:
              </p>
              <ul className="list-disc pl-6 mb-8 text-blue-700">
                <li className="mb-3"><strong className="text-blue-900">Lead with Licenses and Certifications:</strong> Place these prominently at the top of your resume. Include your license number and expiration date.</li>
                <li className="mb-3"><strong className="text-blue-900">Highlight Patient Care:</strong> Describe your experience in patient care, emphasizing your bedside manner and ability to handle diverse patient populations.</li>
                <li className="mb-3"><strong className="text-blue-900">Demonstrate Medical Knowledge:</strong> Use industry-specific terminology to show your familiarity with medical procedures, equipment, and best practices.</li>
                <li className="mb-3"><strong className="text-blue-900">Emphasize Continuing Education:</strong> Healthcare is an ever-evolving field. Highlight any recent training or workshops you've attended.</li>
              </ul>

              <h2 id="marketing" className="text-3xl font-bold text-blue-800 mt-12 mb-6">Marketing: Selling Your Skills</h2>
              <p className="mb-6 text-blue-600">
                In marketing, your resume is your first campaign. Here's how to market yourself effectively:
              </p>
              <ul className="list-disc pl-6 mb-8 text-blue-700">
                <li className="mb-3"><strong className="text-blue-900">Showcase Creativity:</strong> Describe innovative campaigns you've worked on. Consider including a link to a digital portfolio showcasing your best work.</li>
                <li className="mb-3"><strong className="text-blue-900">Quantify Your Success:</strong> Use metrics to demonstrate the success of your campaigns. For example, "Increased social media engagement by 200% through targeted content strategy".</li>
                <li className="mb-3"><strong className="text-blue-900">Highlight Digital Proficiency:</strong> List your experience with various marketing tools and platforms, such as Google Analytics, SEO tools, social media management platforms, and CRM software.</li>
                <li className="mb-3"><strong className="text-blue-900">Emphasize Adaptability:</strong> Marketing trends change rapidly. Highlight your ability to stay current with industry trends and adapt to new platforms and technologies.</li>
              </ul>

              <p className="text-xl text-blue-700 mt-8">
                Remember, your resume is your personal marketing tool. Tailor it to speak directly to the needs and expectations of your target industry, and you'll significantly increase your chances of landing that dream job. Keep it concise, relevant, and impactful, and don't forget to proofread!
              </p>
            </div>
          </article>
        </div>
      </main>
      <Hero />
      <Footer />
    </div>
  );
}

export default IndustrySpecificResumes;