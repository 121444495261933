import React from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Footer from '../components/Footer';

function CommonResumeMistakes() {
  return (
    <div className="font-sans bg-blue-50">
      <Header />
      <main className="w-full px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex flex-col md:flex-row bg-white shadow-lg rounded-lg overflow-hidden border-t-4 border-blue-500">
          <aside className="md:w-1/4 bg-blue-100 p-6 sticky top-0 h-screen overflow-y-auto">
            <nav>
              <ul className="space-y-4">
                <li>
                  <a href="https://resumefeedback.pro#introduction" className="block text-blue-700 hover:text-blue-900 transition-colors duration-200">
                    <span className="font-semibold">Introduction</span>
                    <p className="text-sm text-blue-600">The impact of resume mistakes</p>
                  </a>
                </li>
                <li>
                  <a href="https://resumefeedback.pro#typos" className="block text-blue-700 hover:text-blue-900 transition-colors duration-200">
                    <span className="font-semibold">Typos and Grammatical Errors</span>
                    <p className="text-sm text-blue-600">The importance of proofreading</p>
                  </a>
                </li>
                <li>
                  <a href="https://resumefeedback.pro#generic-resume" className="block text-blue-700 hover:text-blue-900 transition-colors duration-200">
                    <span className="font-semibold">Generic Resumes</span>
                    <p className="text-sm text-blue-600">Tailoring your application</p>
                  </a>
                </li>
                <li>
                  <a href="https://resumefeedback.pro#irrelevant-info" className="block text-blue-700 hover:text-blue-900 transition-colors duration-200">
                    <span className="font-semibold">Irrelevant Information</span>
                    <p className="text-sm text-blue-600">Focusing on what matters</p>
                  </a>
                </li>
                <li>
                  <a href="https://resumefeedback.pro#email-address" className="block text-blue-700 hover:text-blue-900 transition-colors duration-200">
                    <span className="font-semibold">Unprofessional Email Address</span>
                    <p className="text-sm text-blue-600">Creating a professional image</p>
                  </a>
                </li>
                <li>
                  <a href="https://resumefeedback.pro#keywords" className="block text-blue-700 hover:text-blue-900 transition-colors duration-200">
                    <span className="font-semibold">Missing Keywords</span>
                    <p className="text-sm text-blue-600">Optimizing for ATS</p>
                  </a>
                </li>
                <li>
                  <a href="https://resumefeedback.pro#length" className="block text-blue-700 hover:text-blue-900 transition-colors duration-200">
                    <span className="font-semibold">Resume Length</span>
                    <p className="text-sm text-blue-600">Keeping it concise</p>
                  </a>
                </li>
              </ul>
            </nav>
          </aside>
          <article className="md:w-3/4 px-6 py-8 sm:p-10">
            <h1 className="text-4xl sm:text-5xl font-extrabold text-blue-900 mb-4 leading-tight">
              Avoid These Common Resume Mistakes
            </h1>
            <div className="prose prose-blue max-w-none">
              <p id="introduction" className="lead text-xl text-blue-700 mb-8">
                Even small mistakes can cost you valuable opportunities. Learn about these common resume errors and how to avoid them to maximize your chances of landing your dream job.
              </p>

              <h2 id="typos" className="text-3xl font-bold text-blue-800 mt-12 mb-6">1. Typos and Grammatical Errors</h2>
              <p className="mb-6 text-blue-600">
                Careless mistakes can make you appear unprofessional and inattentive to detail. Always proofread your resume multiple times and consider having someone else review it as well.
              </p>

              <h2 id="generic-resume" className="text-3xl font-bold text-blue-800 mt-12 mb-6">2. Using a Generic Resume for All Applications</h2>
              <p className="mb-6 text-blue-600">
                One size doesn't fit all when it comes to resumes. Tailor your resume for each job application to highlight relevant skills and experiences that match the specific job requirements.
              </p>

              <h2 id="irrelevant-info" className="text-3xl font-bold text-blue-800 mt-12 mb-6">3. Including Irrelevant Information</h2>
              <p className="mb-6 text-blue-600">
                Your resume should be focused and targeted. Include only information that's pertinent to the job you're applying for. Irrelevant details can distract from your key qualifications.
              </p>

              <h2 id="email-address" className="text-3xl font-bold text-blue-800 mt-12 mb-6">4. Using an Unprofessional Email Address</h2>
              <p className="mb-6 text-blue-600">
                First impressions matter, even in email addresses. Use a simple, professional email address, ideally based on your name. Avoid nicknames or humorous email addresses in professional contexts.
              </p>

              <h2 id="keywords" className="text-3xl font-bold text-blue-800 mt-12 mb-6">5. Neglecting to Include Keywords</h2>
              <p className="mb-6 text-blue-600">
                Many companies use Applicant Tracking Systems (ATS) to screen resumes. Incorporate relevant keywords from the job description to increase your chances of passing these initial screenings.
              </p>

              <h2 id="length" className="text-3xl font-bold text-blue-800 mt-12 mb-6">6. Making Your Resume Too Long</h2>
              <p className="mb-6 text-blue-600">
                Recruiters often have limited time to review each resume. Keep your resume concise and impactful, ideally no more than two pages for most positions. Focus on your most relevant and recent experiences.
              </p>
            </div>
          </article>
        </div>
      </main>
      <section className="bg-blue-100 py-12">
        <div className="w-full px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-blue-900 mb-4">Ready to Perfect Your Resume?</h2>
          <p className="text-xl text-blue-600 mb-8">
            Don't let common mistakes hold you back. Use our AI-powered tool to get instant, personalized feedback and ensure your resume stands out for all the right reasons!
          </p>
        </div>
      </section>
      <Hero />
      <Footer />
    </div>
  );
}

export default CommonResumeMistakes;
