import React from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Footer from '../components/Footer';

function ATSOptimization() {
  return (
    <div className="font-sans bg-blue-50">
      <Header />
      <main className="w-full px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex flex-col md:flex-row bg-white shadow-lg rounded-lg overflow-hidden border-t-4 border-blue-500">
          <aside className="md:w-1/4 bg-blue-100 p-6 sticky top-0 h-screen overflow-y-auto">
            <nav>
              <ul className="space-y-4">
                <li>
                  <a href="https://resumefeedback.pro#introduction" className="block text-blue-700 hover:text-blue-900 transition-colors duration-200">
                    <span className="font-semibold">Introduction</span>
                    <p className="text-sm text-blue-600">Understanding ATS</p>
                  </a>
                </li>
                <li>
                  <a href="https://resumefeedback.pro#standard-sections" className="block text-blue-700 hover:text-blue-900 transition-colors duration-200">
                    <span className="font-semibold">Standard Sections</span>
                    <p className="text-sm text-blue-600">Structuring your resume</p>
                  </a>
                </li>
                <li>
                  <a href="https://resumefeedback.pro#keywords" className="block text-blue-700 hover:text-blue-900 transition-colors duration-200">
                    <span className="font-semibold">Keywords</span>
                    <p className="text-sm text-blue-600">Incorporating relevant terms</p>
                  </a>
                </li>
                <li>
                  <a href="https://resumefeedback.pro#formatting" className="block text-blue-700 hover:text-blue-900 transition-colors duration-200">
                    <span className="font-semibold">Formatting</span>
                    <p className="text-sm text-blue-600">Keeping it simple</p>
                  </a>
                </li>
                <li>
                  <a href="https://resumefeedback.pro#file-formats" className="block text-blue-700 hover:text-blue-900 transition-colors duration-200">
                    <span className="font-semibold">File Formats</span>
                    <p className="text-sm text-blue-600">Choosing the right format</p>
                  </a>
                </li>
                <li>
                  <a href="https://resumefeedback.pro#layout-tips" className="block text-blue-700 hover:text-blue-900 transition-colors duration-200">
                    <span className="font-semibold">Layout Tips</span>
                    <p className="text-sm text-blue-600">Optimizing resume layout</p>
                  </a>
                </li>
              </ul>
            </nav>
          </aside>
          <article className="md:w-3/4 px-6 py-8 sm:p-10">
            <h1 className="text-4xl sm:text-5xl font-extrabold text-blue-900 mb-4 leading-tight">
              Mastering ATS: Optimizing Your Resume for Success
            </h1>
            <div className="prose prose-blue max-w-none">
              <p id="introduction" className="lead text-xl text-blue-700 mb-8">
                In today's digital job market, Applicant Tracking Systems (ATS) act as the first gatekeeper for your resume. Learn how to optimize your resume to pass through ATS and land in front of human recruiters.
              </p>

              <h2 id="standard-sections" className="text-3xl font-bold text-blue-800 mt-12 mb-6">1. Use Standard Resume Sections</h2>
              <p className="mb-6 text-blue-600">
                Structure your resume with clearly labeled sections that ATS can easily recognize:
              </p>
              <ul className="list-disc pl-6 mb-8 text-blue-700">
                <li className="mb-3"><strong className="text-blue-900">Work Experience:</strong> Highlight your professional journey</li>
                <li className="mb-3"><strong className="text-blue-900">Education:</strong> Showcase your academic achievements</li>
                <li className="mb-3"><strong className="text-blue-900">Skills:</strong> List relevant technical and soft skills</li>
              </ul>

              <h2 id="keywords" className="text-3xl font-bold text-blue-800 mt-12 mb-6">2. Incorporate Relevant Keywords</h2>
              <p className="mb-6 text-blue-600">
                ATS scans for specific keywords to determine relevance. Here's how to incorporate them effectively:
              </p>
              <ul className="list-disc pl-6 mb-8 text-blue-700">
                <li className="mb-3"><strong className="text-blue-900">Job Description Analysis:</strong> Identify key terms and skills mentioned in the job posting</li>
                <li className="mb-3"><strong className="text-blue-900">Industry Jargon:</strong> Use relevant industry-specific terms</li>
                <li className="mb-3"><strong className="text-blue-900">Natural Integration:</strong> Weave keywords naturally into your experience and skills sections</li>
              </ul>

              <h2 id="formatting" className="text-3xl font-bold text-blue-800 mt-12 mb-6">3. Keep Formatting Simple</h2>
              <p className="mb-6 text-blue-600">
                Complex formatting can confuse ATS. Stick to these guidelines:
              </p>
              <ul className="list-disc pl-6 mb-8 text-blue-700">
                <li className="mb-3"><strong className="text-blue-900">Clean Layout:</strong> Use a single-column format for easy parsing</li>
                <li className="mb-3"><strong className="text-blue-900">Standard Fonts:</strong> Choose common, readable fonts like Arial or Calibri</li>
                <li className="mb-3"><strong className="text-blue-900">Minimal Graphics:</strong> Avoid images, charts, or complex design elements</li>
              </ul>

              <h2 id="file-formats" className="text-3xl font-bold text-blue-800 mt-12 mb-6">4. Use Standard File Formats</h2>
              <p className="mb-6 text-blue-600">
                Ensure your resume is in a format that ATS can easily read:
              </p>
              <ul className="list-disc pl-6 mb-8 text-blue-700">
                <li className="mb-3"><strong className="text-blue-900">.docx:</strong> Microsoft Word format is widely accepted</li>
                <li className="mb-3"><strong className="text-blue-900">.pdf:</strong> Use PDF if specified, ensuring it's a text-based PDF</li>
                <li className="mb-3"><strong className="text-blue-900">Avoid:</strong> .pages, .txt, or other non-standard formats</li>
              </ul>

              <h2 id="layout-tips" className="text-3xl font-bold text-blue-800 mt-12 mb-6">5. Optimize Resume Layout</h2>
              <p className="mb-6 text-blue-600">
                Fine-tune your resume's structure for ATS compatibility:
              </p>
              <ul className="list-disc pl-6 mb-8 text-blue-700">
                <li className="mb-3"><strong className="text-blue-900">Avoid Headers/Footers:</strong> Place all important information in the main body</li>
                <li className="mb-3"><strong className="text-blue-900">Use Standard Section Titles:</strong> "Work Experience" instead of "Professional Journey"</li>
                <li className="mb-3"><strong className="text-blue-900">Consistent Formatting:</strong> Use the same date format throughout</li>
              </ul>

              <p className="text-xl text-blue-700 mt-8">
                By following these ATS optimization strategies, you'll significantly increase your resume's chances of passing through automated screening and reaching human recruiters. Remember, a well-optimized resume is your first step towards landing your dream job in the digital age!
              </p>
            </div>
          </article>
        </div>
      </main>
      <section className="bg-blue-100 py-12">
        <div className="w-full px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-blue-900 mb-4">Ready to Optimize Your Resume for ATS?</h2>
          <p className="text-xl text-blue-600 mb-8">
            Don't let your resume get lost in the ATS void. Use our AI-powered tool to ensure your resume is optimized for both ATS and human readers. Get started now and increase your chances of landing that interview!
          </p>
        </div>
      </section>
      <Hero />
      <Footer />
    </div>
  );
}

export default ATSOptimization;
